<template>
	<div class="portcallsFormHeader puiformheader">
		<v-layout wrap>
			<v-flex xs12 md12>
				<v-row dense>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
						<pui-field :label="this.$t('header.portcalls.visitid')" :value="getID"></pui-field>
					</v-col>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
						<pui-field :label="this.$t('header.portcalls.imo')" :value="getIMO"></pui-field>
					</v-col>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
						<pui-field :label="this.$t('header.portcalls.shipname')" :value="getSHIP"></pui-field>
					</v-col>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
						<pui-field :label="this.$t('header.portcalls.porttra')" :value="getPORT"></pui-field>
					</v-col>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
						<pui-field :label="this.$t('header.portcalls.status')" :value="getSTATUS"></pui-field>
					</v-col>
				</v-row>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'portcallsFormHeader',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'portcalls'
		};
	},
	computed: {
		getID() {
			return this.model && this.model.visitid ? this.model.visitid : '';
		},
		getPORT() {
			return this.model && this.model.porttra ? this.model.porttra : '';
		},
		getSHIP() {
			return this.model && this.model.shipname ? this.model.shipname : '';
		},
		getIMO() {
			return this.model && this.model.imo ? this.model.imo : '';
		},
		getSTATUS() {
			return this.model && this.model.statusdescription ? this.model.statusdescription : '';
		}
	}
};
</script>
